<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">证书管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">制证任务管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
          <div class="searchbox">
            <div title="发证日期" class="searchboxItem">
              <span class="itemLabel" style="min-width: 8em;">发证日期:</span>
              <el-date-picker clearable size="small" v-model="issueDate" type="daterange" range-separator="至"
                start-placeholder="起始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div>
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="handleAddOrEdit()">新增</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" min-width="100" />
              <el-table-column label="任务说明" align="left" prop="taskExplain" show-overflow-tooltip min-width="200" />
              <el-table-column label="发证机构" align="left" prop="authorityName" show-overflow-tooltip min-width="160" />
              <el-table-column label="发证日期" align="left" prop="issueDate" show-overflow-tooltip min-width="120" />
              <el-table-column label="成绩发布状态" align="center" prop="scorePublishState" show-overflow-tooltip
                min-width="100">
                <template slot-scope="scope">
                  <span v-if="!scope.row.scorePublishState">{{ scope.row.scorePublishState ? '已发布' : '待发布' }}</span>
                  <el-tooltip v-else
                              effect="dark"
                              :content="scope.row.scoreQueryDateline"
                              placement="right"
                  >
                    <span >{{ scope.row.scorePublishState ? '已发布' : '待发布' }} <i class="el-icon-info"></i></span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="证书发布状态" align="right" prop="certPublishState" show-overflow-tooltip min-width="100">
                <template slot-scope="scope">{{ scope.row.certPublishState ? '已发布' : '待发布' }}</template>
              </el-table-column>
              <el-table-column label="发证人数" align="right" prop="certificationNum" show-overflow-tooltip min-width="100" />

              <el-table-column label="操作" align="center" width="240px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="toStudentManagement(scope.row)">学员管理</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="handleDownload(scope.row)">下载</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini" :disabled="scope.row.scorePublishState"
                    @click="handleUpdateScorePublishState(scope.row.taskId)">成绩发布</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini" :disabled="scope.row.certPublishState"
                    @click="handleUpdateCertPublishState(scope.row.taskId)">证书发布</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="900px" @close="handleClose"
      :close-on-click-modal="false">
      <div>
        <el-form ref="baseform" label-width="150px" label-position="right" class="form" :rules="rules" :model="baseform">
          <el-form-item label="任务说明：" prop="taskExplain">
            <el-input v-model="baseform.taskExplain" size="small" maxlength="50" show-word-limit type="textarea"
              :resize="false" :rows="2" placeholder="请输入任务说明"></el-input>
          </el-form-item>
          <el-form-item label="发证机构：" prop="authorityId">
            <el-select size="small" v-model="baseform.authorityId" remote :remote-method="getCompanyList" filterable @change="authorityIdChange"
              clearable placeholder="请至少输入两个字搜索"><el-option v-for="item in CompanyList" :key="item.authorityId"
                :label="item.authorityName" :value="item.authorityId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发证日期：" prop="issueDate">
            <el-date-picker size="small" format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="baseform.issueDate"
              type="date" placeholder="选择发证日期" />
          </el-form-item>
          <el-form-item label="学员来源：" prop="userSource">
            <el-select v-model="baseform.userSource" placeholder="请选择学员来源" size="small" clearable>
              <el-option v-for="item in userSourceData" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预警天数：" prop="warningDay" class="form-item">
            证书到期提前
            <el-input-number style="width: 150px;" v-model="baseform.warningDay" :step="1" :min="0" :precision="0"
              :controls="false" size="small"></el-input-number>天提醒
          </el-form-item>
          <el-form-item label="证书编号生成规则：" prop="certNoRole" class="form-item">
            <el-radio-group v-model="baseform.certNoRole">
              <el-radio v-for="(item, index) in certNoRoleList" :key="index" :label="item.value">{{
                item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="baseform.certNoRole === '10'" label="选择证书编号规则：" prop="authorityCertId" class="form-item">
            <el-select
                size="small"
                v-model="baseform.authorityCertId"
                remote
                :remote-method="selectEnabledRuleList"
                filterable
                clearable
                placeholder="请输入规则名称搜索"
            >
              <el-option
                  v-for="item in certRuleSelectList"
                  :key="item.authorityCertId"
                  :label="item.certRoleName"
                  :value="item.authorityCertId"
              >
                <span style="float: left">{{ item.certRoleName }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">当前编号:{{ item.currentCode }}(剩余{{item.enableNum}}可用)</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择证书模板：" prop="templateCode" class="form-item">
            <el-radio-group v-model="baseform.templateCode">
              <el-radio :label="item.templateCode" v-for="(item, index) in templateList" :key="index">
                <el-image style="width: 100px; height: 100px" :src="item.certificateImageShow"
                  :preview-src-list="[item.certificateImageShow]" @click.prevent="justSeeImg">
                </el-image>
                <p>{{ item.templateName }}</p>
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <span style="display: flex; justify-content: flex-end">
          <el-button @click="handleClose">取 消</el-button>
          <el-button class="bgc-bv" @click="handleSubmit('baseform')">确 定</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
        title="成绩发布"
        :visible.sync="dialogVisibleForScore"
        width="30%"
        :before-close="doClose"
        :close-on-click-modal="false"
        center
    >
      <div style="text-align: center">
        <div >
          <div style="display: inline-block;">查询截止日期</div>
          <div style="display: inline-block;margin-left: 10px;width: 65%">
            <el-date-picker
                v-model="scoreQueryDateline"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
        </div>
        <div style="margin-top: 30px;">
            <span>
              <i class="el-icon-warning"></i>
              确认发布成绩吗？成绩一经发布将不可取消，请确认信息是否正确！
            </span>
        </div>
      </div>
      <span
          slot="footer"
          class="dialog-footer"
          style="display: flex; justify-content: center"
      >
        <el-button @click="doClose()">取消</el-button>
        <el-button type="primary" @click="getSure()">确认发布</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "voucherPreparationTaskList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {

    return {
      issueDate: "",
      taskId: "",
      scoreQueryDateline: '',// 成绩查询截止日期

      dialogTitle: '新增制证任务',
      dialogVisible: false,
      dialogVisibleForScore: false,
      baseform: {
        taskExplain: '',// 任务说明
        authorityId: '',// 发证机构
        issueDate: '',// 发证日期
        userSource: '',// 学员来源
        templateCode: '',// 证书模板
        warningDay: undefined, // 预警天数
        certNoRole: "", // 证书编号生成规则
        authorityCertId: null, // 证书编号生成规则
      },

      // 发证机构list
      CompanyList: [],
      //所属行业下拉数据
      userSourceData: [],
      //证书模板下拉数据
      templateList: [],
      // 获取 - 证书编号生成规则 
      certNoRoleList: [],
      certRuleSelectList: [],
      rules: {
        taskExplain: [
          { required: true, message: "请输入任务说明", trigger: "blur" },
        ],
        authorityId: [
          { required: true, message: "请选择发证机构", trigger: "change" },
        ],
        issueDate: [
          { required: true, message: "请选择发证日期", trigger: "change" },
        ],
        userSource: [
          { required: true, message: "请选择学员来源", trigger: "change" },
        ],
        certNoRole: [
          { required: true, message: "请选择证书编号生成规则", trigger: "change" },
        ],
        templateCode: [
          { required: true, message: "请选择证书模板", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() {
    this.getUserSource();
    this.getTemplateList();
    this.getInvoice_type();
  },
  methods: {
    authorityIdChange() {
      this.baseform.authorityCertId = null;
      this.certRuleSelectList = [];
      if (this.baseform.authorityId) {
        this.selectEnabledRuleList();
      }
    },
    selectEnabledRuleList(query) {
      if (!this.baseform.authorityId) {
        this.certRuleSelectList = [];
        return;
      }
      this.$post("/biz/cms/authority/cert/selectEnabledRuleList", { certRoleName: query,
        authorityId: this.baseform.authorityId, pageNum:1, pageSize:10 },3000, true, 6)
          .then((res) => {
            if (res.status == 0) {
              this.certRuleSelectList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
    },
    // 成绩发布
    handleUpdateScorePublishState(taskId) {
      this.dialogVisibleForScore = true;
      this.taskId = taskId;
    },
    doClose(){
      this.dialogVisibleForScore = false;
      this.clearInfo();
    },
    getSure(){
      let params = {
        taskId: this.taskId,
        scoreQueryDateline: this.scoreQueryDateline,
      };
      if(!params.taskId){
        this.$message.warning("任务不存在！");
        return;
      }
      if(!params.scoreQueryDateline){
        this.$message.warning("请选择查询截止日期！");
        return
      }
      this.$post("/biz/cms/task/updateScorePublishState ", params, 3000, true, 6)
        .then((res) => {
          if (res.status == 0) {
            this.$message.success('成绩发布成功！')
            this.getData(-1)
          }
        })
        .catch(() => {
          return;
        })
        .finally(() =>{
          this.dialogVisibleForScore = false;
          this.clearInfo();
        });
    },
    clearInfo(){
      this.taskId = "";
      this.scoreQueryDateline = "";
    },
    // 证书发布
    handleUpdateCertPublishState(taskId) {
      this.$confirm("没有证书编号的学员不会被发布，一经发布不能撤回，请确认信息是否正确！", "提示", {
        confirmButtonText: "确认发布",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/biz/cms/task/updateCertPublishState", { taskId }, 3000, true, 6)
            .then((res) => {
              if (res.status == 0) {
                this.$message.success('证书发布成功！')
                this.getData(-1)
              }
            })
            .catch(() => {
              return;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消发布",
          });
        });

    },
    // 仅看图不触发选中
    justSeeImg() {
      return
    },
    getTemplateList() {
      let _this = this
      this.$post("/biz/cms/task/templateList", {}, 3000, true, 6)
        .then((res) => {
          if (res.status == 0) {
            _this.templateList = res.data
          }
        })
        .catch(() => {
          return;
        });
    },
    //获取学员来源
    getUserSource() {
      const userSource = this.$setDictionary("CMS_USER_SOURCE", "list");
      for (const key in userSource) {
        // console.log(userSource)
        this.userSourceData.push({
          value: key,
          label: userSource[key],
        });
      }
    },
    //两个字搜索 - 所属机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/biz/cms/authority/pageList", { authorityName: query, pageNum: 1, pageSize: 100000 }, 3000, true, 6)
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data.list || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.issueDate && this.issueDate.length > 0) {
        params.issueDateStart = this.issueDate[0]
        params.issueDateEnd = this.issueDate[1]

      }
      this.doFetch({
        url: "/biz/cms/task/pageList",
        params,
        pageNum,
      }, true, 6);
    },
    //编辑
    handleAddOrEdit() {
      this.dialogTitle = '新增制证任务'
      this.dialogVisible = true
    },
    // 获取 - 获取字典
    getInvoice_type() {
      // 培训等级
      let arr = this.$setDictionary("CMS_CERT_NO_ROLE", "list");
      for (const key in arr) {
        this.certNoRoleList.push({
          label: arr[key],
          value: key
        });
      }
    },
    // 学员管理
    toStudentManagement(row) {
      this.$router.push({
        path: "/web/certificateManage/certificateIssuingStudentList",
        query: {
          taskId: row.taskId,
          templateCode: row.templateCode,
          userSource: row.userSource,
          certNoRole: row.certNoRole,
          certificationNoNum: row.certificationNoNum,
        },
      });
    },
    // 下载
    handleDownload(row) {
      if (row.certNoRole == '20' && row.certificationNoNum > 0) {
        this.$confirm(
          "有学员没有证书编号，是否继续下载",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$post('/biz/cms/task/cert/download', {
              taskId: row.taskId,
              templateCode: row.templateCode
            }, 3000, true, 6).then((res) => {
              if (res.status == "0") {
                for (let item of res.data) {
                  if (!this.downloadItems.includes(item.taskId)) {
                    this.$store.dispatch("pushDownloadItems", item.taskId);
                  } else {
                    this.$message.warning(
                      "[" + item.fileName + "]已经申请下载,请耐心等待"
                    );
                  }
                }
              } else {
                this.$message.error(res.message);
              }
            })
              .catch((err) => {
                return;
              });
          })
          .catch(() => {
            return;
          });
      } else {
        this.$post('/biz/cms/task/cert/download', {
          taskId: row.taskId,
          templateCode: row.templateCode
        }, 3000, true, 6).then((res) => {
          if (res.status == "0") {
            if (res.data.length) {
              for (let item of res.data) {
                if (!this.downloadItems.includes(item.taskId)) {
                  this.$store.dispatch("pushDownloadItems", item.taskId);
                } else {
                  this.$message.warning(
                    "[" + item.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              }
            }
          } else {
            this.$message.error(res.message);
          }
        })
          .catch((err) => {
            return;
          });
      }
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post('/biz/cms/task/insert', {
            ...this.baseform
          }, 3000, true, 6)
            .then((ret) => {
              if (ret.status == 0) {
                this.$message({
                  type: "success",
                  message: ret.message,
                });
                this.getData(-1)
                this.handleClose();
              }
            })
            .catch((err) => {
              return;
            });
        } else {
          return false;
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.baseform = {
        taskExplain: '',// 任务说明
        authorityId: '',// 发证机构
        issueDate: '',// 发证日期
        userSource: '',// 学员来源
        templateCode: '',// 证书模板
      };
      this.$refs["baseform"].resetFields();
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 2.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<style lang="less" scoped>
/deep/.el-cascader {
  margin-top: 3px;
}

/deep/.el-input-number--small {
  width: 100%;
}

.labelRowTwo /deep/ .el-form-item__label {
  line-height: 20px;
}

/deep/.el-dialog .el-input__icon {
  position: relative;
  top: -3px !important;
}

/deep/.el-textarea .el-input__count {
  line-height: 20px;
}

.centerLabelCenterBox /deep/ .el-form-item__label {
  text-align: center !important;
  padding: 0 !important;
}

.addlist {
  h3 {
    padding: 10px 0;
  }
}

.zsBtn {
  margin-left: 20px;

  button {
    height: 40px;
  }
}

.certificate-box {
  >div:last-child {
    border: 0;
  }
}

.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
</style>
